import React, { useState } from 'react';
import ServiceProvider from './ServiceProvider';
import {
  InputField,
  SelectField,
  DatePickerInputField,
  TextareaField,
  RadioField,
} from '../../components/FormikFields';
import { useNavigate } from 'react-router-dom';
import { Modal } from '../../components/shared/Modal';
import cn from 'classnames';
import styles from './ServiceProvider.styles.scss';
import { Button } from '../../components/shared/Button';
import { useTranslation } from 'react-i18next';
import { DILIGENCE } from './ServiceProvider.config';

const ServiceProviderContainer = () => {
  const [selectedDiligence, setSelectedDiligence] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const history = useNavigate();
  const { i18n, t } = useTranslation();

  const getInputType = (type) => {
    switch (type) {
      case 'text': {
        return InputField;
      }
      case 'select': {
        return SelectField;
      }
      case 'datepicker': {
        return DatePickerInputField;
      }
      case 'textarea': {
        return TextareaField;
      }
      case 'radio': {
        return RadioField;
      }
    }
  };

  const onDiligenceChange = (e) => {
    let _selectedDiligence = [...selectedDiligence];
    if (e.checked) {
      _selectedDiligence.push(e.value);
    } else {
      _selectedDiligence = _selectedDiligence.filter(
        (diligence) => diligence.key !== e.value.key,
      );
    }

    setSelectedDiligence(_selectedDiligence);
  };

  const handleCancelClick = () => history('/');

  const deepEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const areArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = arr1
      .slice()
      .sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
    const sortedArr2 = arr2
      .slice()
      .sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));

    return sortedArr1.every((obj1, index) =>
      deepEqual(obj1, sortedArr2[index]),
    );
  };

  const handleFormSubmit = (e) => {
    if (!areArraysEqual(DILIGENCE, selectedDiligence)) {
      const element = document.getElementById('diligence');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    } else {
      setOpenModal(true);
    }

    console.log({ e });
  };

  return (
    <>
      <ServiceProvider
        onDiligenceChange={onDiligenceChange}
        selectedDiligence={selectedDiligence}
        getInputType={getInputType}
        handleCancelClick={handleCancelClick}
        handleFormSubmit={handleFormSubmit}
      />
      <Modal
        className={styles['submission-modal']}
        open={openModal}
        withCloseIcon={false}
      >
        <div>
          <p className="mb-4">{t('service_provider_form.thank_you')}</p>
          <p className="mb-2">
            <strong>{t('service_provider_form.reference_number')}:</strong>
          </p>
          <p className="mb-4">
            {t('service_provider_form.your_reference_number')}{' '}
            <strong>1234562714</strong>.{' '}
            {t('service_provider_form.please_keep')}
          </p>
          <p className="mb-2">
            <strong>{t('service_provider_form.important_info')}:</strong>
          </p>
          <p className="mb-4">{t('service_provider_form.processing_time')}</p>
          <p className="mb-3">
            <strong>{t('service_provider_form.contact')}:</strong>{' '}
            {t('service_provider_form.ota_may_reach')}
          </p>
          <p>{t('service_provider_form.any_inquiries')}</p>

          <div className={cn('mt-4', styles['onboarding-btn-container'])}>
            <Button
              onClick={() =>
                history(`/${i18n.language}/service-provider/dashboard`)
              }
            >
              {t('service_provider_form.go_to_dashboard')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(ServiceProviderContainer);
