import instances from '../config/axios';
// import { invoicesList, invoiceById, registeredSolution } from './mocks';

export const generateOtp = (vat, numberOfOtps = 1) => {
  return instances.servicesInstance.post(
    `/onboarding/generateOtp?otpNum=${numberOfOtps}`,
    {},
    { headers: { VAT: vat } },
  );
};

export const startProcess = () => {
  return instances.servicesInstance.post(
    '/onboarding/start',
    {},
    {
      headers: {
        callLogin: false,
      },
    },
  );
};

export const generateInvoice = (data, tax_number) => {
  return instances.smeInstance.post('/sme/invoices', data, {
    headers: {
      tax_number,
    },
  });
};

export const getInvoicesList = async (taxnumber) => {
  // return invoicesList();
  return instances.smeInstance.get('/sme/invoices/taxnumber', {
    headers: {
      taxnumber,
    },
  });
};

export const getInvoiceById = async (id, tax_number) => {
  // return invoiceById();
  return instances.smeInstance.get('/sme/invoices/' + id, {
    headers: {
      tax_number,
    },
  });
};

export const getStatistics = async (tax_number) => {
  const certifications = await instances.servicesInstance.get(
    `/onboarding/${tax_number}/certificationState`,
  );
  const registrations = await instances.servicesInstance.get(
    `/onboarding/${tax_number}/registrationStatus`,
  );
  const eInvoicing = await instances.certificationInstance.get(
    `/invoice/${tax_number}/invoiceState`,
  );

  if (certifications && registrations && eInvoicing) {
    return {
      data: {
        certifications: {
          active: certifications?.data?.isActive,
          inProcess: certifications?.data?.inProcess,
          inActive: certifications?.data?.isInActive,
          expired: certifications?.data?.isExpired,
        },
        registrations: {
          active: registrations?.data?.isActive,
          inProcess: registrations?.data?.inProcess,
          inActive: registrations?.data?.isInActive,
          expired: registrations?.data?.isExpired,
        },
        eInvoicing: {
          submitted: eInvoicing?.data?.isSubmitted,
          rejected: eInvoicing?.data?.isRejected,
        },
      },
    };
  } else {
    throw new Error('');
  }
};

export const getInvoiceOnboardData = (tax_number, invoiceType) => {
  return instances.smeInstance.get(
    `/sme/invoices/onboard/${tax_number}?invoiceType=${invoiceType}`,
  );
};

export const printInvoice = (data) => {
  return instances.printInstance.post('/print/api/generatePdf', data);
};

export const getRegisteredSolutions = async (taxNumber) => {
  // return registeredSolution();
  return instances.servicesInstance.get(
    '/onboarding/listPcsid?vatNumber=' + taxNumber,
  );
};

export const getTaxReturnsList = async (taxNumber) => {
  return instances.taxReturnServiceInstance.get('/return/getReports', {
    headers: {
      vatNumber: taxNumber,
    },
  });
};

export const getDataByPeriod = ({
  taxnumber,
  startDate,
  endDate,
  periodCount,
  referenceNumber = null,
}) => {
  if (referenceNumber === 'null' || referenceNumber === null) {
    referenceNumber = '';
  }
  return instances.taxReturnServiceInstance.get(
    '/return/getReportDataByPeriod',
    {
      headers: {
        vatNumber: taxnumber,
        startDate,
        endDate,
        'Period-Count': periodCount,
        referenceNumber,
      },
    },
  );
};

export const approveReturn = ({
  vatNumber,
  startDate,
  endDate,
  periodCount,
  data,
}) => {
  return instances.taxReturnServiceInstance.post(
    '/return/approveReturn',
    data,
    {
      headers: {
        'Period-Count': periodCount,
        vatNumber,
        startDate,
        endDate,
      },
    },
  );
};

export const getSalesRegister = ({
  taxNumber,
  dateFrom,
  dateTo,
  invoiceType,
  searchQuery,
}) => {
  return instances.taxReturnServiceInstance.get('/return/sales', {
    headers: {
      taxnumber: taxNumber,
    },
    params: {
      dateFrom,
      dateTo,
      invoiceType,
      searchQuery,
    },
  });
};

export const getPurchasesRegister = ({
  taxNumber,
  dateFrom,
  dateTo,
  invoiceType,
  searchQuery,
}) => {
  return instances.taxReturnServiceInstance.get('/return/purchases', {
    headers: {
      taxnumber: taxNumber,
    },
    params: {
      dateFrom,
      dateTo,
      invoiceType,
      searchQuery,
    },
  });
};

export const revokeCertificate = async (serialNumber) => {
  return instances.servicesInstance.post(`/onboarding/${serialNumber}/revoke`);
};

export const getInvoiceViewBySellerTaxId = (sellerTaxId) => {
  return instances.servicesInstance.get(
    `onboarding/serviceProvider/getAllTaxDataBySellerTaxId/${sellerTaxId}`,
  );
};

export const getInvoiceViewByBuyerTaxId = (buyerTaxId) => {
  return instances.servicesInstance.get(
    `onboarding/serviceProvider/getAllTaxDataByBuyerTaxId/${buyerTaxId}`,
  );
};
