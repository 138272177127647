import React from 'react';
import { Button } from '../../../components/shared/Button';
import styles from './ServiceProviderTable.styles.scss';

export const ROUTES = [
  {
    label: 'service_provider_dashboard.service_provider_dashboard',
    link: '',
  },
  {
    label: 'service_provider',
    link: '/service-provider',
  },
];

export const COLUMNS = ({ onProceedClick, t }) => [
  {
    label: 'service_provider_dashboard.legal_entity_name',
    value: 'legalEntityName',
  },
  {
    label: 'service_provider_dashboard.reference_number',
    value: 'referenceNumber',
  },
  {
    label: 'service_provider_dashboard.status',
    value: 'status',
    body: (rowData) =>
      rowData.status === 'PENDING' ? (
        <p className={styles['approval-status-pending']}>
          {t('service_provider_dashboard.pending_approval')}
        </p>
      ) : rowData.status === 'PENDING_CERT' ? (
        <p className={styles['approval-status-pending-cert']}>
          {t('service_provider_dashboard.pending_cert')}
        </p>
      ) : (
        <p className={styles['approval-status-approved']}>
          {t('service_provider_dashboard.approved')}
        </p>
      ),
  },
  {
    label: '',
    value: 'actions',
    body: (rowData) =>
      rowData.status === 'PENDING_CERT' ? (
        <Button
          className={'px-3'}
          style={{ fontSize: '13px' }}
          onClick={onProceedClick}
          // disabled={rowData.status === 'PENDING'}
        >
          {t('service_provider_dashboard.proceed')}
        </Button>
      ) : (
        <></>
      ),
  },
];
