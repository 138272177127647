export const INITIAL_VALUES = {
  legalEntityName: 'Al Faisool Enterprises LLC',
  taxIdentificationNumber: '123456789',
  tradingBusinessNames: '',
  registeredAddress: '',
  companyRegistrationNumber: '987654321',
  organizationsWebsiteAddress: '',
  briefOverviewOfYourEntityActivities: '',
  repName: '',
  repNumber: '',
  repPosition: '',
  repEmail: '',
  dirName: '',
  dirNumber: '',
  dirPosition: '',
  dirEmail: '',
  isOpenPeppolMember: '',
  peppolMemberProof: '',
  isOtherPeppolVerified: '',
  peppolAuthorityDetails: '',
  nameOfInvoicingSolution: '',
  organizationDirectLinkToEinvoicingWebPage: '',
  briefDescriptionOfEinvoicingSolution: '',
  ListedOnOta: '',
  OwnSpSolution: '',
  howDoYouIntendToActAsSp: '',
  approximateGoLiveDate: '',
};
