import React from 'react';
import styles from './Tile.styles.scss';
import cn from 'classnames';

const Tile = ({ className, children, id, ishovered, onHover, aspectRatio }) => {
  return (
    <div
      className={cn(
        styles.container,
        className,
        aspectRatio === 1 ? styles['aspect-ratio1'] : styles['aspect-ratio1_4'],
      )}
      id={id}
    >
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          ishovered,
          tileid: id,
          onHover,
        });
      })}
    </div>
  );
};

export default React.memo(Tile);
