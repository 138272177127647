import React from 'react';
import styles from './InvoicesListHeader.styles.scss';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

const InvoicesListHeader = () => {
  const { t } = useTranslation();
  return (
    <div className={cn(styles.header)}>
      <p className="mb-4">{t('view_invoices')}</p>
    </div>
  );
};

export default React.memo(InvoicesListHeader);
