import React from 'react';
import { Icon } from '../../../../../../components/shared/Icon';
import { useNavigate } from 'react-router-dom';
import styles from './ViewButton.styles.scss';
import cn from 'classnames';

const ViewButton = ({ rowData }) => {
  const history = useNavigate();
  const handleClick = () => {
    history(
      `view-invoice/${rowData.uniqueReferenceIdentifier}/${rowData.invoiceType}`,
    );
  };

  return (
    <Icon
      onClick={handleClick}
      className={cn('mx-2', styles.button)}
      name={'viewInvoice'}
    />
  );
};

export default React.memo(ViewButton);
