import React from 'react';
import { MainLayout } from '../../components/MainLayout';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { InvoicesListHeader } from './components/InvoicesListHeader';
import { Filters } from './components/Filters';
import { InvoicesTable } from './components/InvoicesTable';
import cn from 'classnames';
import styles from './InvoiceView.styles.scss';
import { ROUTES } from './InvoiceView.config';
import { SelectButton } from 'primereact/selectbutton';
import { useTranslation } from 'react-i18next';

const InvoiceView = ({
  invoices,
  originalInvoices,
  setRenderInvoices,
  loading,
  view,
  setView,
  viewOptions,
}) => {
  const { i18n } = useTranslation();

  return (
    <MainLayout className={cn(styles.container)}>
      <div className="container">
        <Breadcrumbs routeNames={ROUTES} />
        <InvoicesListHeader />
        <Filters
          originalInvoices={originalInvoices}
          setRenderInvoices={setRenderInvoices}
        />
        <div className="flex justify-content-center">
          <SelectButton
            value={view}
            onChange={(e) => setView(e.value)}
            style={{
              textAlign: i18n.language === 'ar' ? 'end' : 'start',
              direction: 'ltr',
            }}
            options={viewOptions}
            pt={{
              button: (option) => ({
                style:
                  option.value === view
                    ? { backgroundColor: '#26890d', color: 'white' } // Active button
                    : {},
              }),
            }}
          />
        </div>
        <InvoicesTable view={view} invoices={invoices} loading={loading} />
      </div>
    </MainLayout>
  );
};

export default React.memo(InvoiceView);
