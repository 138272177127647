import React, { useEffect } from 'react';
import styles from './Header.styles.scss';
import Logo from '../../assets/images/deloitte_logo.png';
import OtaLogo from '../../assets/images/logo.png';
import { Icon } from '../shared/Icon';
import cn from 'classnames';
import { LanguageSelect } from './components/LanguageSelect';
import { SideBarMenu } from './components/SideBarMenu';
import { useKeycloak } from '../../hooks/useKeycloak';

const Header = ({ t, onHomeClick, user, logout }) => {
  const { realm, userInfo } = useKeycloak();
  const env = realm;

  const logo = env === 'OTA' ? OtaLogo : Logo;

  return (
    <div className={cn(styles.header)}>
      <div className={cn(styles.logoAndHome, 'fluid col-4')}>
        <img
          src={logo}
          onClick={onHomeClick}
          className={cn(styles.logo)}
          alt="logo"
        />
      </div>
      <div className={cn(styles.innerDiv, 'col-4')}>
        <LanguageSelect
          className={cn(styles.changeLanguage, 'col-3 d-sm-none d-md-block')}
          iconName={'arrowDown'}
        />
        <div className={cn('col-9 fluid', styles.nameAndLang)}>
          <div id="logout_btn" className={cn(styles.logoutBtn, 'col-2')}>
            <Icon onClick={logout} name="logoutLogo" />
          </div>
          <div className={cn(styles.name, 'col-sm-12 col-md-5 mx-2')}>
            <p className="mb-0">{t('common.welcome')}</p>
            <p>{userInfo?.firstName + ' ' + userInfo?.lastName}</p>
          </div>
        </div>
      </div>

      <div className={cn('', styles.sideBarMenu)}>
        <SideBarMenu />
      </div>
    </div>
  );
};

export default React.memo(Header);
