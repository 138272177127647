import React from 'react';
import styles from './InvoicesListHeader.styles.scss';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

const InvoicesListHeader = ({ statistics }) => {
  const { t } = useTranslation();
  return (
    <div className={cn(styles.header)}>
      <p className="mb-4">{t('invoice_view')}</p>
      {/* <div className={cn(styles.statistics, 'row')}>
        <p className="col-md-3 col-sm-12">{`(${t(
          'invoice_list.generated_this_year',
          {
            value: statistics.thisYear,
            limit: statistics.limit,
          },
        )}`}</p>
        <p className="col-md-3 col-sm-12">{`${t(
          'invoice_list.invoices_generated_all_time',
          {
            value: statistics.invoicesAllTime,
          },
        )}`}</p>
        <p className="col-md-3 col-sm-12">{`${t(
          'invoice_list.notes_generated_all_time',
          {
            value: statistics.notesAllTime,
          },
        )}`}</p>
        <p className="col-md-3 col-sm-12">{`${t('invoice_list.total', {
          value: statistics.total,
        })})`}</p>
      </div> */}
    </div>
  );
};

export default React.memo(InvoicesListHeader);
