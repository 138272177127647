export const PAGES = (SHOW_SME) => [
  //   {
  //     title: 'solution_certificate',
  //     iconName: 'solCert',
  //     buttonName: 'Know More',
  //     redirectUrl: 'solution-certificate',
  //   },
  {
    title: 'sme',
    iconName: 'sme',
    buttonName: 'invoicing',
    redirectUrl: 'sme',
    hide: !SHOW_SME,
  },
  {
    title: 'solution_registration',
    iconName: 'solReg',
    buttonName: 'register',
    redirectUrl: 'solution-registration',
  },
  {
    title: 'service_provider',
    iconName: 'serviceProvider',
    redirectUrl: 'service-provider',
  },
  {
    title: 'dashboard_and_statistics',
    iconName: 'dashStat',
    buttonName: 'statistics',
    redirectUrl: 'dashboard',
  },
  {
    title: 'invoice_view',
    iconName: 'viewInvoices',
    redirectUrl: 'invoice-view',
  },
];
export const ROUTES = [
  {
    label: 'breadcrumb_e_invoicing',
    link: '',
  },
];
