import {
  NOTE_TYPES,
  ADDITIONAL_SELLER_ID_TYPES,
  CITIES,
  ADDITIONAL_BUYER_ID_TYPES,
  PAYMENT_METHOD,
  NOTE_ISSUANCE_REASONS,
  PREVIOUS_INVOICES,
} from '../../../../../config/invoice/options';
import {
  onValuesBlur,
  onVatRateChange,
  setOriginalInvoiceGross,
  handleSupplyStartDateChange,
  getTaxTypes,
} from '../../../../../helpers/invoiceHelpers';
import moment from 'moment';
import { FIELDS_MAX_LENGTH } from './validations';

export const invoiceTypeInputs = ({ type, language }) => {
  return [
    {
      name: 'invoiceTypeDescription',
      type: 'text',
      label: 'createInvoice.invoice_type_description',
      id: 'invoiceTypeDescription',
      disabled: true,
      hide: false,
      required: false,
      className: 'col-6',
    },
    {
      name: 'invoiceTypeTransaction',
      type: 'text',
      label: 'createInvoice.invoice_type_transaction',
      id: 'invoiceTypeTransaction',
      disabled: true,
      hide: false,
      required: false,
      className: 'col-6',
    },
    {
      name: 'noteType',
      type: 'select',
      options: NOTE_TYPES(language),
      label: 'createInvoice.type_of_note',
      id: 'noteType',
      disabled: false,
      hide: type !== 'creditDebit',
      required: false,
      className: 'col-6',
    },
  ];
};

export const invoiceInformationInputs = ({
  values,
  setFieldValue,
  setFieldTouched,
}) => [
  {
    name: 'invoiceReferenceNumber',
    type: 'text',
    label: 'createInvoice.invoice_reference_number',
    id: 'invoiceReferenceNumber',
    disabled: true,
    hide: false,
    required: false,
  },
  {
    name: 'invoiceIssueDate',
    type: 'text',
    label: 'createInvoice.invoice_issue_date',
    id: 'invoiceIssueDate',
    disabled: true,
    hide: false,
    required: false,
  },
  {
    name: 'supplyStartDate',
    type: 'datepicker',
    label: 'createInvoice.supply_start_date',
    id: 'supplyStartDate',
    className: 'col-6',
    disabled: false,
    hide: false,
    required: true,
    disabledDays: [{ after: new Date() }],
    onChange: (value) =>
      handleSupplyStartDateChange({ value, setFieldTouched, setFieldValue }),
  },
  {
    name: 'supplyEndDate',
    type: 'datepicker',
    label: 'createInvoice.supply_end_date',
    id: 'supplyEndDate',
    className: 'col-6',
    disabled: !values.supplyStartDate,
    hide: false,
    disabledDays: [
      { before: moment(values.supplyStartDate, 'DD/MM/YYYY').toDate() },
    ],
    required: true,
  },
];

export const sellerIdentificationInputs = ({ initialValues, language }) => [
  {
    name: 'sellerEnglishName',
    type: 'text',
    label: 'createInvoice.seller_name',
    id: 'sellerEnglishName',
    disabled: false,
    hide: false,
    required: true,
    maxlength: FIELDS_MAX_LENGTH.name,
  },
  {
    name: 'vatNumber',
    type: 'text',
    label: 'createInvoice.vat_registration_number',
    id: 'vatNumber',
    disabled: initialValues.vatNumber,
    hide: false,
    required: true,
    className: 'col-6',
  },
  {
    name: 'groupVatNumber',
    type: 'text',
    label: 'createInvoice.group_vat_registration_number',
    id: 'groupVatNumber',
    disabled: true,
    hide: false,
    required: false,
    className: 'col-6',
  },
  {
    name: 'additionalSellerIdType',
    type: 'select',
    label: 'createInvoice.additional_seller_id_type',
    id: 'additionalSellerIdType',
    disabled: false,
    options: ADDITIONAL_SELLER_ID_TYPES(language),
    hide: false,
    required: false,
    className: 'col-6',
  },
  {
    name: 'additionalSellerId',
    type: 'text',
    label: 'createInvoice.additional_seller_id_number',
    id: 'additionalSellerId',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.idNumber,
    className: 'col-6',
  },
];

export const sellerAddressInputs = ({ language }) => [
  {
    name: 'sellerStreetEn',
    type: 'text',
    label: 'createInvoice.street_name',
    id: 'sellerStreetEn',
    disabled: false,
    hide: false,
    required: true,
    maxlength: FIELDS_MAX_LENGTH.address,
  },
  {
    name: 'additionalSellerStreetEn',
    type: 'text',
    label: 'createInvoice.additional_street',
    id: 'additionalSellerStreetEn',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.address,
  },
  {
    name: 'sellerBuildingNumber',
    type: 'text',
    label: 'createInvoice.building_number',
    id: 'sellerBuildingNumber',
    disabled: false,
    hide: false,
    required: true,
    maxlength: FIELDS_MAX_LENGTH.buildingNumber,
    className: 'col-6',
  },
  {
    name: 'additionalSellerBuildingNumber',
    type: 'text',
    label: 'createInvoice.additional_number',
    id: 'additionalSellerBuildingNumber',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.buildingNumber,
    className: 'col-6',
  },
  {
    name: 'sellerCity',
    type: 'select',
    options: CITIES(language),
    label: 'createInvoice.city',
    id: 'sellerCity',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-6',
  },
  {
    name: 'sellerPostalCode',
    type: 'text',
    label: 'createInvoice.postal_code',
    id: 'sellerPostalCode',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-6',
    maxlength: FIELDS_MAX_LENGTH.postalCode,
  },
  {
    name: 'sellerStateEn',
    type: 'text',
    label: 'createInvoice.province_state',
    id: 'sellerStateEn',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-6',
    maxlength: FIELDS_MAX_LENGTH.address,
  },
  {
    name: 'sellerNeighborhoodEn',
    type: 'text',
    label: 'createInvoice.neighborhood',
    id: 'sellerNeighborhoodEn',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-6',
    maxlength: FIELDS_MAX_LENGTH.address,
  },
  {
    name: 'sellerCountryCode',
    type: 'text',
    label: 'createInvoice.country_code',
    id: 'sellerCountryCode',
    disabled: true,
    hide: false,
    required: false,
    className: 'col-6',
  },
];

export const buyerIdentificationInputs = ({ language }) => [
  {
    name: 'buyerEnglishName',
    type: 'text',
    label: 'createInvoice.buyer_name',
    id: 'buyerEnglishName',
    disabled: false,
    hide: false,
    required: true,
    maxlength: FIELDS_MAX_LENGTH.name,
  },
  {
    name: 'buyerVatNumber',
    type: 'text',
    label: 'createInvoice.vat_registration_number',
    id: 'buyerVatNumber',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.VAT,
  },
  {
    name: 'buyerGroupVatNumber',
    type: 'text',
    label: 'createInvoice.group_vat_registration_number',
    id: 'buyerGroupVatNumber',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.VAT,
  },
  {
    name: 'additionalBuyerIdType',
    type: 'select',
    label: 'createInvoice.additional_buyer_id_type',
    id: 'additionalBuyerIdType',
    disabled: false,
    options: ADDITIONAL_BUYER_ID_TYPES(language),
    hide: false,
    required: false,
    className: 'col-6',
  },
  {
    name: 'additionalBuyerId',
    type: 'text',
    label: 'createInvoice.additional_buyer_id_number',
    id: 'additionalBuyerId',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.idNumber,
    className: 'col-6',
  },
];

export const buyerAddressInputs = ({ language }) => [
  {
    name: 'buyerStreetEn',
    type: 'text',
    label: 'createInvoice.street_name',
    id: 'buyerStreetEn',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.address,
  },
  {
    name: 'additionalBuyerStreetEn',
    type: 'text',
    label: 'createInvoice.additional_street',
    id: 'additionalBuyerStreetEn',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.address,
  },
  {
    name: 'buyerBuildingNumber',
    type: 'text',
    label: 'createInvoice.building_number',
    id: 'buyerBuildingNumber',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.buildingNumber,
    className: 'col-6',
  },
  {
    name: 'additionalBuyerBuildingNumber',
    type: 'text',
    label: 'createInvoice.additional_number',
    id: 'additionalBuyerBuildingNumber',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.buildingNumber,
    className: 'col-6',
  },
  {
    name: 'buyerCity',
    type: 'select',
    options: CITIES(language),
    label: 'createInvoice.city',
    id: 'buyerCity',
    disabled: false,
    hide: false,
    required: false,
    className: 'col-6',
  },
  {
    name: 'buyerPostalCode',
    type: 'text',
    label: 'createInvoice.postal_code',
    id: 'buyerPostalCode',
    disabled: false,
    hide: false,
    required: false,
    className: 'col-6',
    maxlength: FIELDS_MAX_LENGTH.postalCode,
  },
  {
    name: 'buyerStateEn',
    type: 'text',
    label: 'createInvoice.province_state',
    id: 'buyerStateEn',
    disabled: false,
    hide: false,
    required: false,
    className: 'col-6',
    maxlength: FIELDS_MAX_LENGTH.address,
  },
  {
    name: 'buyerNeighborhoodEn',
    type: 'text',
    label: 'createInvoice.neighborhood',
    id: 'buyerNeighborhoodEn',
    disabled: false,
    hide: false,
    required: false,
    className: 'col-6',
    maxlength: FIELDS_MAX_LENGTH.address,
  },
  {
    name: 'buyerCountryCode',
    type: 'text',
    label: 'createInvoice.country_code',
    id: 'buyerCountryCode',
    disabled: true,
    hide: false,
    required: false,
    className: 'col-6',
  },
];

export const orderReferenceInputs = () => [
  {
    name: 'purchaseOrderNumber',
    type: 'text',
    label: 'createInvoice.purchase_order_number',
    id: 'purchaseOrderNumber',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.orderReferenceInputs,
  },
  {
    name: 'contactNumber',
    type: 'text',
    label: 'createInvoice.contract_number',
    id: 'contactNumber',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.contractNumber,
  },
];

export const lineItemsInputs = ({
  values,
  setFieldValue,
  taxCountry,
  language,
}) => [
  {
    name: 'tempItem.descriptionEn',
    type: 'text',
    label: 'createInvoice.goods_or_service_description',
    id: 'descriptionEn',
    disabled: false,
    hide: false,
    required: true,
    maxlength: FIELDS_MAX_LENGTH.description,
  },
  {
    name: 'tempItem.unitPrice',
    type: 'text',
    label: 'createInvoice.unit_price_qar',
    id: 'unitPrice',
    disabled: false,
    hide: false,
    required: true,
    onBlur: () => onValuesBlur({ item: values.tempItem, setFieldValue }),
    className: 'col-6',
  },
  {
    name: 'tempItem.quantity',
    type: 'text',
    label: 'createInvoice.quantity',
    id: 'quantity',
    disabled: false,
    hide: false,
    required: true,
    onBlur: () => onValuesBlur({ item: values.tempItem, setFieldValue }),
    className: 'col-6',
  },
  {
    name: 'tempItem.discountPercentage',
    type: 'text',
    label: 'createInvoice.discount_or_rebate_percentage',
    id: 'discountPercentage',
    disabled: values?.tempItem?.discountAmount > 0,
    hide: false,
    required: false,
    onBlur: () => onValuesBlur({ item: values.tempItem, setFieldValue }),
    className: 'col-6',
  },
  {
    name: 'tempItem.discountAmount',
    type: 'text',
    label: 'createInvoice.discount_or_rebate_amount_qar',
    id: 'discountAmount',
    disabled: values?.tempItem?.discountPercentage > 0,
    hide: false,
    required: false,
    onBlur: () => onValuesBlur({ item: values.tempItem, setFieldValue }),
    className: 'col-6',
  },
  {
    name: 'tempItem.vatRateType',
    type: 'select',
    label: 'createInvoice.tax_code_for_vat',
    id: 'vatRateType',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-6',
    options: getTaxTypes('OTA', language),
    onChange: (e) =>
      onVatRateChange(e, {
        setFieldValue,
        item: values.tempItem,
        vatTypes: getTaxTypes(taxCountry, 'en'),
      }),
  },
  {
    name: 'tempItem.vatRate',
    type: 'text',
    label: 'createInvoice.vat_rate',
    id: 'vatRate',
    disabled: true,
    hide: false,
    required: false,
    className: 'col-6',
  },
  {
    name: 'tempItem.vatAmount',
    type: 'text',
    label: 'createInvoice.vat_amount_qar',
    id: 'vatAmount',
    disabled: true,
    hide: false,
    required: false,
    className: 'col-6',
  },
];

export const paymentTermsInputs = ({ values, language }) => [
  {
    name: 'paymentMethod',
    type: 'select',
    label: 'createInvoice.payment_method',
    id: 'paymentMethod',
    disabled: false,
    hide: false,
    required: true,
    options: PAYMENT_METHOD(language),
    className: 'col-6',
  },
  {
    name: 'bankNameEn',
    type: 'text',
    label: 'createInvoice.bank_name',
    id: 'bankNameEn',
    disabled: false,
    hide: values.paymentMethod !== 'BANK_TRANSFER',
    required: true,
    maxlength: FIELDS_MAX_LENGTH.bankName,
    className: 'col-6',
  },
  {
    name: 'accountNumber',
    type: 'text',
    label: 'createInvoice.account_number',
    id: 'accountNumber',
    disabled: false,
    hide: values.paymentMethod !== 'BANK_TRANSFER',
    required: true,
    maxlength: FIELDS_MAX_LENGTH.accountNumber,
    className: 'col-6',
  },
  {
    name: 'paymentTermsEn',
    type: 'text',
    label: 'createInvoice.payment_terms',
    id: 'paymentTermsEn',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.paymentTerms,
    className: 'col-6',
  },
];

export const referenceInputs = ({
  previousInvoices,
  setFieldValue,
  language,
}) => [
  {
    name: 'originalInvoiceReference',
    type: 'select',
    label: 'createInvoice.reference_to_original_invoice',
    id: 'originalInvoiceReference',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-6',
    options: PREVIOUS_INVOICES({ previousInvoices, language }),
    onChange: (e) =>
      setOriginalInvoiceGross({ e, previousInvoices, setFieldValue }),
  },
  {
    name: 'originalGrossAmount',
    type: 'text',
    label: 'createInvoice.gross_amount_of_referenced_invoice',
    id: 'originalGrossAmount',
    disabled: true,
    hide: false,
    required: false,
    className: 'col-6',
  },
  {
    name: 'noteReasonOfIssuance',
    type: 'select',
    label: 'createInvoice.reason_for_credit_debit_note_issuance',
    id: 'noteReasonOfIssuance',
    disabled: false,
    hide: false,
    required: true,
    options: NOTE_ISSUANCE_REASONS(language),
    className: 'col-6',
  },
];

export const notesInputs = () => [
  {
    name: 'specialTaxTreatmentEn',
    type: 'text',
    label: 'createInvoice.special_tax_treatment',
    id: 'specialTaxTreatmentEn',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.specialTaxTreatment,
  },
  {
    name: 'notesEn',
    type: 'textarea',
    label: 'createInvoice.notes',
    id: 'notesEn',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.notes,
  },
];
