export const PURCHASES_INPUTS = (isReduced) => [
  {
    fieldName: 'purchases.standardRatedPurchase',
    title: 'Standard Rated domestic purchases 15%',
    adjustmentName: 'newPurchases.standardRatedAdj',
    taxAmountName: 'purchases.standardRateTaxAmount',
    reasonName: 'newPurchases.standardRateReason',
  },
  {
    fieldName: 'purchases.reducedRate',
    title: 'Standard Rated domestic purchases 5%',
    adjustmentName: 'newPurchases.reducedRateAdj',
    taxAmountName: 'purchases.reducedRateTaxAmount',
    reasonName: 'newPurchases.reducedRateReason',
    hide: !isReduced,
  },
  {
    fieldName: 'purchases.importsSubjectToVat',
    title: 'Imports subject to VAT paid at customs (15%)',
    adjustmentName: 'newPurchases.importsSubjectToVatAdj',
    taxAmountName: 'purchases.importsSubjectToVatTaxAmount',
    reasonName: 'newPurchases.importsReason',
  },
  {
    fieldName: 'purchases.reducedImportsSubjectToVat',
    title: 'Imports subject to VAT paid at customs (5%)',
    adjustmentName: 'newPurchases.reducedImportsSubjectToVatAdj',
    taxAmountName: 'purchases.reducedImportsSubjectToVatTaxAmount',
    reasonName: 'newPurchases.reducedImportsReason',
    hide: !isReduced,
  },
  {
    fieldName: 'purchases.importsReverseCharge',
    title:
      'Imports subject to VAT accounted for through reverse charge mechanisms (15%)',
    adjustmentName: 'newPurchases.reverseImportsAdj',
    taxAmountName: 'purchases.importsReverseChargeTaxAmount',
    reasonName: 'newPurchases.reverseImportsReason',
  },
  {
    fieldName: 'purchases.reducedImportsReverseCharge',
    title:
      'Imports subject to VAT accounted for through reverse charge mechanisms (5%)',
    adjustmentName: 'newPurchases.reducedReverseImportsAdj',
    taxAmountName: 'purchases.reducedImportsReverseChargeTaxAmount',
    reasonName: 'newPurchases.reducedReverseImportsReason',
    hide: !isReduced,
  },
  {
    fieldName: 'purchases.zeroRatedSales',
    title: 'Zero-rated Purchases',
    adjustmentName: 'newPurchases.zeroRatedAdj',
    reasonName: 'newPurchases.zeroReason',
    hideTaxField: true,
  },
  {
    fieldName: 'purchases.exempt',
    title: 'Exempt Purchases',
    adjustmentName: 'newPurchases.exemptAdj',
    reasonName: 'newPurchases.exemptReason',
    hideTaxField: true,
  },
  {
    fieldName: 'purchases.totalPurchases',
    title: 'Total Purchases',
    adjustmentName: 'newPurchases.totalPurchasesAdj',
    taxAmountName: 'purchases.totalPurchasesTaxAmount',
    disabled: true,
    bold: true,
  },
  {
    fieldName: '',
    title: 'Total VAT due for current period',
    adjustmentName: '',
    taxAmountName: 'currentTotalVatDue',
    disabled: true,
    bold: true,
    hideValueField: true,
    hideAdjField: true,
    hideReasonField: true,
  },
  {
    fieldName: '',
    title: 'Corrections from previous period (between OMR 5,000+/-)',
    adjustmentName: '',
    taxAmountName: 'correctionFromPrevious',
    hideValueField: true,
    hideAdjField: true,
    hideReasonField: true,
  },
  {
    fieldName: '',
    title: 'VAT credit carried forward from previous period(s)',
    adjustmentName: '',
    taxAmountName: 'vatCreditCarriedForward',
    disabled: true,
    hideValueField: true,
    hideAdjField: true,
    hideReasonField: true,
  },
  {
    fieldName: '',
    title: 'Net VAT Due (or claim)',
    adjustmentName: '',
    taxAmountName: 'netVatDue',
    disabled: true,
    bold: true,
    hideValueField: true,
    hideAdjField: true,
    hideReasonField: true,
  },
];

export const PURCHASES_REASON_OPTIONS = [
  {
    label: '',
    value: '',
  },
  {
    label: 'Proportional Deduction',
    value: 'PROPORTIONAL_DEDUCTION',
  },
  {
    label: 'Timing difference',
    value: 'TIMING_DIFFERENCE',
  },
  {
    label: 'Non-business expense',
    value: 'NON_BUSINESS',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];
