import {
  COMPANY_INFORMATION,
  ADDITIONAL_INFORMATION,
  SOLUTION_INFORMATION,
} from './formConfig/inputs';

export const ROUTES = [
  {
    label: 'service_provider',
    link: '',
  },
];

export const FORM_CONFIG = () => [
  {
    title: 'company_information',
    fields: COMPANY_INFORMATION(),
  },
  {
    title: 'accreditation_information',
    fields: ADDITIONAL_INFORMATION(),
  },
  {
    title: 'eInvoicing_solution_information',
    fields: SOLUTION_INFORMATION(),
  },
  // {
  //   title: 'due_diligence_check',
  // },
  // {
  //   title: 'document_upload',
  // },
];

export const DILIGENCE = [
  {
    label: 'confirm_reg_business',
    key: 'business',
  },
  {
    label: 'confirm_not_insolvent',
    key: 'insolvent',
  },
  {
    label: 'confirm_senior_office_holders',
    key: 'seniorOffice',
  },
];

export const ATTACHMENTS = [
  {
    label: 'signed_SP_agreement',
    name: 'signed_SP_agreement',
  },
  {
    label: 'criminal_conviction_history',
    name: 'criminal_conviction_history',
  },
  {
    label: 'indemnity_insurance',
    name: 'indemnity_insurance',
  },
  {
    label: 'company_registration_document',
    name: 'company_registration_document',
  },
  {
    label: 'privacy_statement',
    name: 'privacy_statement',
  },
];

export const DIRECTIONS = {
  welcomeText: 'welcome_text',
  steps: [
    {
      text: 'step_1',
      key: 'step_1',
    },
    {
      text: 'step_2',
      key: 'step_2',
    },
    {
      text: 'step_3',
      key: 'step_3',
    },
    {
      text: 'step_4',
      key: 'step_4',
    },
  ],
};

export const REQUIREMENTS = {
  note: 'note',
  requirements: [
    {
      title: 'req_1',
      desc: 'req_1_desc',
      key: 'req_1',
    },
    {
      title: 'req_2',
      desc: 'req_2_desc',
      key: 'req_2',
    },
    {
      title: 'req_3',
      desc: 'req_3_desc',
      key: 'req_3',
    },
    {
      title: 'req_4',
      desc: 'req_4_desc',
      key: 'req_4',
    },
  ],
};
