import React from 'react';
import {
  STANDARD_INVOICE_TYPE_TRANSACTION,
  SIMPLIFIED_INVOICE_TYPE_TRANSACTION,
  TAX_TYPES,
} from '../config/invoice/constants';

import moment from 'moment';
import { INVOICE_TYPES_ENUM } from '../config/invoice/constants';
import QR from 'qrcode-base64';
import { Buffer } from 'buffer';
import { printInvoice } from '../services/apis';
import { setSelectOption } from './common';
import { SELECT_AN_OPTION_LABEL } from '../constants/common';

export const getQrCodeImage = (qrCode) =>
  QR.drawImg(qrCode, {
    typeNumber: 4,
    errorCorrectLevel: 'M',
    size: 300,
  });

export const getInvoiceTypeTransaction = ({ invoiceType }) => {
  return invoiceType.indexOf('standard') !== -1
    ? STANDARD_INVOICE_TYPE_TRANSACTION
    : SIMPLIFIED_INVOICE_TYPE_TRANSACTION;
};

export const isNoteInvoice = (invoiceType) =>
  invoiceType.toLowerCase().indexOf('note') !== -1;

export const validateItemFields = async ({
  setTouched,
  setErrors,
  validateForm,
  item,
  t,
}) => {
  const formErrors = await validateForm();

  const requiredMessage = t('create_invoice_validation.required');

  const errors = [];

  if (!item.unitPrice) {
    errors.push({
      key: 'unitPrice',
      value: requiredMessage,
    });
  }
  if (!item.descriptionEn) {
    errors.push({
      key: 'descriptionEn',
      value: requiredMessage,
    });
  }

  if (!item.quantity) {
    errors.push({
      key: 'quantity',
      value: requiredMessage,
    });
  }

  if (!item.vatRateType) {
    errors.push({
      key: 'vatRateType',
      value: requiredMessage,
    });
  }

  errors.push({
    key: 'descriptionEn',
    value: formErrors?.tempItem?.descriptionEn,
  });
  errors.push({
    key: 'quantity',
    value: formErrors?.tempItem?.quantity,
  });
  errors.push({
    key: 'unitPrice',
    value: formErrors?.tempItem?.unitPrice,
  });
  errors.push({
    key: 'discountAmount',
    value: formErrors?.tempItem?.discountAmount,
  });
  errors.push({
    key: 'discountPercentage',
    value: formErrors?.tempItem?.discountPercentage,
  });

  const errorsToShow = errors
    .filter((error) => !!error.value)
    .reduce(
      (errorsObj, error) => {
        errorsObj.tempItem[error.key] = error.value;

        return errorsObj;
      },
      { tempItem: {} },
    );

  const errorsToTouched = errors
    .filter((error) => !!error.value)
    .reduce(
      (errorsObj, error) => {
        errorsObj.tempItem[error.key] = true;

        return errorsObj;
      },
      { tempItem: {} },
    );
  setTouched(errorsToTouched);
  setErrors(errorsToShow);

  return errors.filter((error) => !!error.value).length > 0;
};

export const calculateItemSubtotalAmount = ({ quantity, unitPrice }) =>
  quantity * unitPrice;

export const calculateItemDiscountAmount = ({
  subtotalAmount,
  discountAmount,
  discountPercentage,
}) => {
  const discountCalculation =
    +discountPercentage > 0 ? (+subtotalAmount * +discountPercentage) / 100 : 0;
  return discountAmount ? +discountAmount : discountCalculation;
};

export const calculateItemDiscountPercentage = ({
  subtotalAmount,
  discountAmount,
  discountPercentage,
}) => {
  if (discountPercentage) {
    return discountPercentage;
  } else {
    return (discountAmount / subtotalAmount) * 100;
  }
};

export const calculateItemTotalAfterDiscount = ({ subtotalAmount, discount }) =>
  +subtotalAmount - +discount;

export const calculateVatAmount = ({ totalAmountAfterDiscount, vatRate }) => {
  const intVatRate = parseInt(vatRate, 10);
  if (intVatRate === 0) {
    return intVatRate;
  } else {
    return (totalAmountAfterDiscount * intVatRate) / 100;
  }
};

export const calculateTotalAmountIncludingTax = ({
  totalAmountAfterDiscount,
  vatAmount,
}) => +totalAmountAfterDiscount + +vatAmount;

export const getProductWithTotals = ({ item, valueToGet }) => {
  const subtotalAmount = calculateItemSubtotalAmount({
    quantity: +item.quantity,
    unitPrice: +item.unitPrice,
  });
  const discount = calculateItemDiscountAmount({
    subtotalAmount,
    discountAmount: +item.discountAmount,
    discountPercentage: +item.discountPercentage,
  });

  const discountPercentage = calculateItemDiscountPercentage({
    subtotalAmount,
    discountAmount: item.discountAmount,
    discountPercentage: item.discountPercentage,
  });

  const totalAmountAfterDiscount = calculateItemTotalAfterDiscount({
    subtotalAmount,
    discount,
  });
  const vatAmount = calculateVatAmount({
    totalAmountAfterDiscount,
    vatRate: item.vatRate,
  });

  const totalAmountIncludingTax = calculateTotalAmountIncludingTax({
    totalAmountAfterDiscount,
    vatAmount,
  });

  const tempObj = {
    subtotalAmount,
    discount,
    discountPercentage,
    totalAmountAfterDiscount,
    vatAmount,
    totalAmountIncludingTax,
  };

  if (valueToGet) {
    return Number(tempObj[valueToGet]).toFixed(3);
  }

  item.subtotalAmount = Number(subtotalAmount).toFixed(3);
  item.discountPercentage = Number(discountPercentage).toFixed(3);
  item.discountAmount = Number(discount).toFixed(3);
  item.vatAmount = Number(vatAmount).toFixed(3);
  item.totalAmount = Number(totalAmountIncludingTax).toFixed(3);
  item.description = (
    <div className="d-flex flex-column">
      <span style={{ fontWeight: 600 }}>{item.descriptionAr}</span>
      <span style={{ fontWeight: 600 }}>{item.descriptionEn}</span>
    </div>
  );

  return item;
};

export const getVatRate = (vatCode) => {
  return TAX_TYPES[vatCode];
};

export const onVatRateChange = (
  e,
  {
    vatTypes,
    setFieldValue,
    item,
    vatRateKey = 'tempItem.vatRate',
    vatAmountKey = 'tempItem.vatAmount',
  },
) => {
  const vatType = vatTypes.filter((type) => type.value === e);
  const vatRate = vatType[0].rate + '%';
  item.vatRate = vatRate;
  const vatAmount = getProductWithTotals({ item, valueToGet: 'vatAmount' });
  setFieldValue(vatRateKey, vatRate);
  if (!isNaN(vatAmount)) {
    setFieldValue(vatAmountKey, vatAmount);
  }
};

export const onValuesBlur = ({
  item,
  setFieldValue,
  vatAmountKey = 'tempItem.vatAmount',
}) => {
  const vatAmount = getProductWithTotals({ item, valueToGet: 'vatAmount' });
  if (!isNaN(vatAmount)) {
    setFieldValue(vatAmountKey, vatAmount);
  }
};

export const setOriginalInvoiceGross = ({
  e,
  previousInvoices,
  setFieldValue,
}) => {
  const gross = previousInvoices.filter((invoice) => invoice.irn === e)[0]
    .originalGrossAmount;
  setFieldValue('originalGrossAmount', gross);
};

export const calculateTotalExcludingTax = (items) =>
  items
    .reduce((amount, item) => +item.quantity * +item.unitPrice + amount, 0)
    .toFixed(3);

export const calculateTotalVatAmount = (items) =>
  items.reduce((amount, item) => +item.vatAmount + amount, 0).toFixed(3);

export const calculateTotalDiscountAmount = (items) =>
  items.reduce((amount, item) => +item.discountAmount + amount, 0).toFixed(3);

export const calculateTotalIncludingTax = (items) =>
  items.reduce((amount, item) => +item.totalAmount + amount, 0).toFixed(3);

export const convertFormDataToApi = ({ data, category, type, taxCountry }) => {
  if (!data) {
    return null;
  }
  const items = [...data.items];

  const TotalExcludingTax = calculateTotalExcludingTax(items);
  const totalDiscountsAmount = calculateTotalDiscountAmount(items);
  const TotalVatAmount = calculateTotalVatAmount(items);
  const tottalIncludingVat = calculateTotalIncludingTax(items);
  const totalPayableAmount = calculateTotalIncludingTax(items);

  const supplyEndDate = moment(data.supplyEndDate, 'DD/MM/YYYY').format(
    'YYYY-MM-DD',
  );

  const formData = {
    invoiceUniqueIdentifier: '',
    vatNumber: data.seller?.vatNumber || '',
    createdDate: moment(data.invoiceIssueDate).format('YYYY-MM-DDTHH:mm:ssZ'),
    invoice_umber: data.invoiceNumber,
    buyerInvoiceNumber: data.buyerInvoiceNumber,
    invoiceIssueDate: moment(data.invoiceIssueDate).format(
      'YYYY-MM-DD h:mm:ss',
    ),
    invoiceType: INVOICE_TYPES_ENUM({
      category,
      type,
      noteType: data.noteType,
    }),
    invoiceTypeTransaction: getInvoiceTypeTransaction({
      invoiceType: category,
    }),
    noteType: data.noteType || null,
    invoiceReferenceNumber: data.invoiceReferenceNumber,
    originalInvoiceReference: data.originalInvoiceReference,
    reasonOfNote: data.noteReasonOfIssuance || null,
    arNotes: data.enNotes,
    enNotes: data.enNotes,
    supplyStartDate: moment(data.supplyStartDate, 'DD/MM/YYYY').format(
      'YYYY-MM-DD',
    ),
    supplyEndDate: supplyEndDate === 'Invalid date' ? '' : supplyEndDate,
    purchaseOrderNumber: data.purchaseOrderNumber,
    contactNumber: data.contactNumber,
    specialTaxTreatmentEn: data.specialTaxTreatmentEn,
    specialTaxTreatmentAr: data.specialTaxTreatmentEn,
    seller: {
      vatNumber: data.seller?.vatNumber || '',
      groupVatNumber: data.seller?.groupVatNumber || '',
      sellerEnglishName: data.seller?.sellerEnglishName,
      sellerArabicName: data.seller?.sellerEnglishName,
      additionalSellerIdType: setSelectOption(
        data.seller?.additionalSellerIdType,
        null,
      ),
      additionalSellerId: data.seller?.additionalSellerId,
      sellerStreetEn: data.seller?.sellerStreetEn,
      sellerStreetAr: data.seller?.sellerStreetEn,
      additionalSellerStreetEn: data.seller?.additionalSellerStreetEn,
      additionalSellerStreetAr: data.seller?.additionalSellerStreetEn,
      sellerBuildingNumber: data.seller?.sellerBuildingNumber,
      additionalSellerBuildingNumber:
        data.seller?.additionalSellerBuildingNumber,
      sellerCity: data.seller?.sellerCity,
      sellerPostalCode: data.seller?.sellerPostalCode,
      sellerStateEn: data.seller?.sellerStateEn,
      sellerStateAr: data.seller?.sellerStateEn,
      sellerNeighborhoodEn: data.seller?.sellerNeighborhoodEn,
      sellerNeighborhoodAr: data.seller?.sellerNeighborhoodEn,
      sellerCountryCode: data.seller?.sellerCountryCode,
    },
    buyer: {
      buyerEnglishName: data.buyer?.buyerEnglishName,
      buyerArabicName: data.buyer?.buyerEnglishName,
      buyerVatNumber: data.buyer?.buyerVatNumber,
      buyerGroupVatNumber: data.buyer?.buyerGroupVatNumber,
      additionalBuyerIdType: setSelectOption(
        data.buyer?.additionalBuyerIdType,
        null,
      ),
      additionalBuyerId: data.buyer?.additionalBuyerId,
      buyerStreetEn: data.buyer?.buyerStreetEn,
      buyerStreetAr: data.buyer?.buyerStreetEn,
      additionalBuyerStreetEn: data.buyer?.additionalBuyerStreetEn,
      additionalBuyerStreetAr: data.buyer?.additionalBuyerStreetEn,
      buyerBuildingNumber: data.buyer?.buyerBuildingNumber,
      additionalBuyerBuildingNumber: data.buyer?.additionalBuyerBuildingNumber,
      buyerCity: setSelectOption(data.buyer?.buyerCity, ''),
      buyerPostalCode: data.buyer?.buyerPostalCode,
      buyerStateEn: data.buyer?.buyerStateEn,
      buyerStateAr: data.buyer?.buyerStateEn,
      buyerNeighborhoodEn: data.buyer?.buyerNeighborhoodEn,
      buyerNeighborhoodAr: data.buyer?.buyerNeighborhoodEn,
      buyerCountryCode: data.buyer?.buyerCountryCode,
    },
    paymentTerms: {
      paymentMethodCode: data.paymentTerms.paymentMethod,
      bankNameEn: data.paymentTerms.bankNameEn,
      bankNameAr: data.paymentTerms.bankNameEn,
      accountNumber: data.paymentTerms.accountNumber,
      paymentTermsEn: data.paymentTerms.paymentTermsEn,
      paymentTermsAr: data.paymentTerms.paymentTermsEn,
    },
    totalAmountExcludingVat: +TotalExcludingTax,
    totalDiscountsAmount: +totalDiscountsAmount,
    totalVatAmount: +TotalVatAmount,
    totalPayableAmount: +totalPayableAmount,
    totalIncludingVat: +tottalIncludingVat,
    grossTotalIncludingVat: +tottalIncludingVat,
    invoiceItemDTOList: items.map((item) => {
      const itemData = {
        descriptionAr: item.descriptionEn,
        descriptionEn: item.descriptionEn,
        unitPrice: +item.unitPrice,
        quantity: +item.quantity,
        subtotalAmount: +item.subtotalAmount,
        discountAmount: +item.discountAmount,
        discountPercentage: +item.discountPercentage,
        vatRateType: getVatRateCode(taxCountry, item.vatRateType),
        vatRate: parseInt(item.vatRate, 10),
        vatAmount: +item.vatAmount,
        totalAmount: +item.totalAmount,
        uuid: item.id,
      };

      return {
        ...itemData,
      };
    }),
  };
  return formData;
};

export const downloadXml = (xml, name) => {
  const decodedXml = Buffer.from(xml, 'base64');
  const fileName = name + '.xml';
  const a = document.createElement('a');
  const file = new Blob([decodedXml], { type: 'text/plain' });
  a.setAttribute('href', window.URL.createObjectURL(file));
  a.setAttribute('download', fileName);
  a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':');
  a.click();
};

export const convertDataToPrint = (data, currency, uuid) => {
  const items = [...data.invoiceItems];

  const totalExcludingTax = calculateTotalExcludingTax(items);
  const totalDiscountsAmount = calculateTotalDiscountAmount(items);
  const totalVatAmount = calculateTotalVatAmount(items);
  const totalIncludingVat = calculateTotalIncludingTax(items);
  const totalPayableAmount = calculateTotalIncludingTax(items);

  return {
    invoiceReferenceNumber: uuid,
    qrCode: getQrCodeImage(data.qrCode),
    einvoiceDTO: {
      uniqueReferenceIdentifier: uuid,
      ...data,
      invoiceItems: data.invoiceItems.map((item) => ({
        ...item,
        currency,
      })),
      totalAmounts: {
        discountTotalAmount: +totalDiscountsAmount,
        totalExcludingVat: +totalExcludingTax,
        vatTotalAmount: +totalVatAmount,
        grossTotalIncludingVat: +totalIncludingVat,
        totalPayableAmount: +totalPayableAmount,
      },
      currency,
    },
  };
};

export const downloadPDFInvoice = ({ data, currency, uuid, setLoading }) => {
  const requestBody = convertDataToPrint(data, currency, uuid);
  printInvoice(requestBody)
    .then((resp) => {
      const pdfResult = resp.data;
      const file = new Blob([pdfResult], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;

      const issueDate = moment(requestBody.einvoiceDTO.issueDate, 'DD-MM-YYYY');
      const vatNumber = requestBody.einvoiceDTO.sellerDTO.vatNumber;
      const irn = requestBody.invoiceReferenceNumber;

      link.download = `${vatNumber}_${issueDate.format('YYYYMMDD')}_${irn}.pdf`;

      link.click();
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
};

export const handleSupplyStartDateChange = ({
  setFieldValue,
  setFieldTouched,
  value,
}) => {
  setFieldTouched('supplyStartDate', true);
  setFieldValue('supplyStartDate', value);
  setFieldValue('supplyEndDate', '');
};

export const getTaxTypes = (country, lang) => {
  if (country === 'KSA') {
    return lang === 'en'
      ? [
          { label: SELECT_AN_OPTION_LABEL, value: '', symbol: '', rate: '' },
          {
            label: 'Standard Rate',
            value: 'STANDARD',
            symbol: 'S',
            rate: '15',
          },
          { label: 'Reduced Rate', value: 'REDUCED', symbol: 'S', rate: '5' },
          { label: 'Zero Rate', value: 'ZERO', symbol: 'Z', rate: '0' },
          { label: 'Exempt Rate', value: 'EXEMPT', symbol: 'E', rate: '0' },
        ]
      : [
          { label: 'اختر', value: '', symbol: '' },
          {
            label: 'الضريبة العادية',
            value: 'STANDARD',
            symbol: 'S',
            rate: '15',
          },
          {
            label: 'الضريبة المخفضة',
            value: 'REDUCED',
            symbol: 'S',
            rate: '5',
          },
          { label: 'الضريبة الصفرية', value: 'ZERO', symbol: 'Z', rate: '0' },
          { label: 'معفي من الضريبة', value: 'EXEMPT', symbol: 'E', rate: '0' },
        ];
  } else {
    return lang === 'en'
      ? [
          { label: SELECT_AN_OPTION_LABEL, value: '', symbol: '' },
          { label: 'Standard Rate', value: 'STANDARD', symbol: 'S', rate: '5' },
          { label: 'Zero Rate', value: 'ZERO', symbol: 'Z', rate: '0' },
          { label: 'Exempt Rate', value: 'EXEMPT', symbol: 'E', rate: '0' },
        ]
      : [
          { label: 'اختر', value: '', symbol: '' },
          {
            label: 'الضريبة العادية',
            value: 'STANDARD',
            symbol: 'S',
            rate: '5',
          },
          { label: 'الضريبة الصفرية', value: 'ZERO', symbol: 'Z', rate: '0' },
          { label: 'معفي من الضريبة', value: 'EXEMPT', symbol: 'E', rate: '0' },
        ];
  }
};

const getVatRateCode = (taxCountry, vatRateType) => {
  const typesObj = getTaxTypes(taxCountry, 'en');
  return typesObj.filter((type) => vatRateType === type.value)[0]?.symbol;
};
