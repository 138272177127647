import React from 'react';
import { Icon } from '../../../../../../components/shared/Icon';

const ContinueButton = ({ rowData }) => {
  return (
    <Icon className={'mx-2'} style={{ direction: 'ltr' }} name={'continue'} />
  );
};

export default React.memo(ContinueButton);
