import React from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { useTranslation } from 'react-i18next';

const RadioField = ({ field, form, setFieldValue, ...restProps }) => {
  const { t } = useTranslation();

  return (
    <div className="row gap-3">
      {restProps?.options?.map((option) => {
        return (
          <div key={option.id} className="flex align-items-center">
            <RadioButton
              inputId={option.id}
              name={field.name}
              value={option.value}
              onChange={(e) => setFieldValue(field.name, e.value)}
              checked={form.values[field.name] === option.value}
            />
            <label htmlFor={option.id} className="mx-2">
              {t(option.label)}
            </label>
          </div>
        );
      })}
    </div>
  );

  //   return <RadioButton {...field} {...restProps} />;
};

export default React.memo(RadioField);
