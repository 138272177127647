import React from 'react';
import { MainLayout } from '../../../components/MainLayout';
import { PagesHeader } from '../../../components/shared/PagesHeader';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import cn from 'classnames';
import styles from './ServiceProviderTable.styles.scss';
import { ROUTES, COLUMNS } from './ServiceProviderTable.config';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';

const ServiceProviderTable = ({
  onProceedClick,
  loading = false,
  data = [],
}) => {
  const { t } = useTranslation();

  return (
    <MainLayout className={cn(styles.container)}>
      <div className="container">
        <Breadcrumbs routeNames={ROUTES} />
        <PagesHeader
          title={t('service_provider_dashboard.service_provider_dashboard')}
        />
        <DataTable
          value={data}
          className="border-rounded my-5"
          style={{ boxShadow: '0px 0px 10px #00000017' }}
          loading={loading}
        >
          {COLUMNS({ onProceedClick, t }).map((column) => (
            <Column
              key={column.label}
              field={column.value}
              header={t(column.label)}
              // sortable={column.sortable}
              body={column.body}
            />
          ))}
        </DataTable>
      </div>
    </MainLayout>
  );
};

export default React.memo(ServiceProviderTable);
