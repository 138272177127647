export const COMPANY_INFORMATION = () => [
  {
    name: 'legalEntityName',
    type: 'text',
    label: 'legal_entity_name',
    id: 'legalEntityName',
    disabled: true,
    hide: false,
    required: true,
    className: 'col-3',
  },
  {
    name: 'taxIdentificationNumber',
    type: 'text',
    label: 'tax_identification_number',
    id: 'taxIdentificationNumber',
    disabled: true,
    hide: false,
    required: true,
    className: 'col-3',
  },
  {
    name: 'tradingBusinessNames',
    type: 'text',
    label: 'trading_business_names',
    id: 'tradingBusinessNames',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-3',
  },
  {
    name: 'registeredAddress',
    type: 'text',
    label: 'registered_address',
    id: 'registeredAddress',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-3',
  },
  {
    name: 'companyRegistrationNumber',
    type: 'text',
    label: 'company_registration_number',
    id: 'companyRegistrationNumber',
    disabled: true,
    hide: false,
    required: true,
    className: 'col-3',
  },
  {
    name: 'organizationsWebsiteAddress',
    type: 'text',
    label: 'organizations_website_address',
    id: 'organizationsWebsiteAddress',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-3',
  },
  {
    name: 'briefOverviewOfYourEntityActivities',
    type: 'textarea',
    label: 'brief_overview_of_your_entity_activities',
    id: 'briefOverviewOfYourEntityActivities',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-7',
  },
  {
    name: 'authorizedRepresentative',
    type: 'multi',
    label: 'authorized_representative',
    id: 'authorizedRepresentative',
    disabled: false,
    hide: false,
    required: true,
    fields: [
      {
        name: 'repName',
        type: 'text',
        label: 'rep_name',
        id: 'repName',
        disabled: false,
        hide: false,
        required: true,
        className: 'col-3',
      },
      {
        name: 'repNumber',
        type: 'text',
        label: 'rep_number',
        id: 'repNumber',
        disabled: false,
        hide: false,
        required: true,
        className: 'col-3',
      },
      {
        name: 'repPosition',
        type: 'text',
        label: 'rep_position',
        id: 'repPosition',
        disabled: false,
        hide: false,
        required: true,
        className: 'col-3',
      },
      {
        name: 'repEmail',
        type: 'text',
        label: 'rep_email',
        id: 'repEmail',
        disabled: false,
        hide: false,
        required: true,
        className: 'col-3',
      },
    ],
  },
  {
    name: 'director',
    type: 'multi',
    label: 'director',
    id: 'director',
    disabled: false,
    hide: false,
    required: true,
    fields: [
      {
        name: 'dirName',
        type: 'text',
        label: 'dir_name',
        id: 'dirName',
        disabled: false,
        hide: false,
        required: true,
        className: 'col-3',
      },
      {
        name: 'dirNumber',
        type: 'text',
        label: 'dir_number',
        id: 'dirNumber',
        disabled: false,
        hide: false,
        required: true,
        className: 'col-3',
      },
      {
        name: 'dirPosition',
        type: 'text',
        label: 'dir_position',
        id: 'dirPosition',
        disabled: false,
        hide: false,
        required: true,
        className: 'col-3',
      },
      {
        name: 'dirEmail',
        type: 'text',
        label: 'dir_email',
        id: 'dirEmail',
        disabled: false,
        hide: false,
        required: true,
        className: 'col-3',
      },
    ],
  },
];
export const ADDITIONAL_INFORMATION = () => [
  {
    name: 'isOpenPeppolMember',
    type: 'radio',
    options: [
      {
        label: 'common.yes',
        value: true,
      },
      {
        label: 'common.no',
        value: false,
      },
    ],
    label: 'is_open_peppol_member',
    id: 'isOpenPeppolMember',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-12',
    withAttachment: true,
    attachment: {
      showWhenValueIs: true,
      label: 'peppol_member_proof',
      name: 'peppolMemberProof',
      id: 'peppolMemberProof',
    },
  },
  {
    name: 'isOtherPeppolVerified',
    type: 'radio',
    options: [
      {
        label: 'common.yes',
        value: true,
      },
      {
        label: 'common.no',
        value: false,
      },
    ],
    label: 'is_other_peppol_verified',
    id: 'isOtherPeppolVerified',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-12',
    withDescription: true,
    descriptionInput: {
      showWhenValueIs: true,
      name: 'peppolAuthorityDetails',
      type: 'textarea',
      label: 'peppol_authority_details',
      id: 'peppolAuthorityDetails',
      disabled: false,
      hide: false,
      className: 'col-7',
    },
  },
];
export const SOLUTION_INFORMATION = () => [
  {
    name: 'nameOfInvoicingSolution',
    type: 'text',
    label: 'name_of_invoicing_solution',
    id: 'nameOfInvoicingSolution',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-3',
  },
  {
    name: 'organizationDirectLinkToEinvoicingWebPage',
    type: 'text',
    label: 'organization_direct_link_to_einvoicing_web_page',
    id: 'organizationDirectLinkToEinvoicingWebPage',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-6',
  },
  {
    name: 'briefDescriptionOfEinvoicingSolution',
    type: 'textarea',
    label: 'brief_description_of_einvoicing_solution',
    id: 'briefDescriptionOfEinvoicingSolution',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-7',
  },
  {
    name: 'ListedOnOta',
    type: 'radio',
    options: [
      {
        label: 'common.yes',
        value: true,
      },
      {
        label: 'common.no',
        value: false,
      },
    ],
    label:
      'would_you_like_your_organization_listed_on_ota_list_of_accredited_service_providers',
    id: 'ListedOnOta',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-12',
    withDescription: false,
    descriptionInput: {
      showWhenValueIs: true,
      name: 'peppolAuthorityDetails',
      type: 'textarea',
      label: 'peppol_authority_details',
      id: 'peppolAuthorityDetails',
      disabled: false,
      hide: false,
      className: 'col-7',
    },
  },
  {
    name: 'OwnSpSolution',
    type: 'radio',
    options: [
      {
        label: 'common.yes',
        value: true,
      },
      {
        label: 'common.no',
        value: false,
      },
    ],
    label: 'will_you_be_building_your_own_sp_solution',
    id: 'OwnSpSolution',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-12',
    withDescription: true,
    descriptionInput: {
      showWhenValueIs: false,
      name: 'howDoYouIntendToActAsSp',
      type: 'textarea',
      label: 'how_do_you_intend_to_act_as_sp',
      id: 'howDoYouIntendToActAsSp',
      disabled: false,
      hide: false,
      className: 'col-7',
    },
  },
  {
    name: 'approximateGoLiveDate',
    type: 'datepicker',
    label: 'approximate_go_live_date',
    id: 'approximateGoLiveDate',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-3',
  },
];
