import moment from 'moment';

export const ROUTES = [
  {
    label: 'invoice_view',
    link: '',
  },
];

export const COLUMNS = [
  {
    label: '',
    value: 'number',
    sortable: false,
  },
  {
    label: 'invoice_list.invoice_type',
    value: 'invoiceType',
    sortable: false,
  },
  {
    label: 'invoice_list.invoice_number',
    value: 'invoiceNumber',
    sortable: false,
  },
  {
    label: 'invoice_list.invoice_issue_date',
    value: 'issueDate',
    body: (rowData) =>
      rowData.issueDate
        ? moment(rowData.issueDate, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY')
        : '-',
    sortable: false,
  },
  {
    label: 'invoice_list.customer_name',
    value: 'englishBuyerName',
    sortable: false,
  },
  {
    label: 'invoice_list.customer_trn',
    value: 'customerTRN',
    sortable: false,
  },
  {
    label: 'invoice_list.invoice_total_excluding_vat',
    value: 'totalExcludingVat',
    sortable: false,
  },
  {
    label: 'invoice_list.invoice_vat',
    value: 'vatValue',
    sortable: false,
  },
  {
    label: 'invoice_list.invoice_gross_total',
    value: 'grossTotalIncludingVat',
    sortable: false,
  },
];

export const BUYER_COLUMNS = [
  {
    label: '',
    value: 'number',
    sortable: false,
  },
  {
    label: 'invoice_list.invoice_type',
    value: 'invoiceType',
    sortable: false,
  },
  {
    label: 'invoice_list.invoice_number',
    value: 'invoiceNumber',
    sortable: false,
  },
  {
    label: 'invoice_list.invoice_issue_date',
    value: 'issueDate',
    body: (rowData) =>
      rowData.issueDate
        ? moment(rowData.issueDate, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY')
        : '-',
    sortable: false,
  },
  {
    label: 'invoice_list.supplier_name',
    value: 'englishSupplierName',
    sortable: false,
  },
  {
    label: 'invoice_list.supplier_trn',
    value: 'supplierTRN',
    sortable: false,
  },
  {
    label: 'invoice_list.invoice_total_excluding_vat',
    value: 'totalExcludingVat',
    sortable: false,
  },
  {
    label: 'invoice_list.invoice_vat',
    value: 'vatValue',
    sortable: false,
  },
  {
    label: 'invoice_list.invoice_gross_total',
    value: 'grossTotalIncludingVat',
    sortable: false,
  },
];

export const VIEW_OPTIONS = [
  {
    label: 'show_as_seller',
    value: 'seller',
  },
  {
    label: 'show_as_buyer',
    value: 'buyer',
  },
];

export const parseSellerInvoices = (sellerInvoices, t) => {
  return sellerInvoices.map((invoice) => ({
    invoiceType: t(`invoiceTypes.${invoice.invoiceTypeCode}`, {
      defaultValue: invoice.invoiceTypeCode,
    }),
    invoiceNumber: invoice.invoiceNumber,
    customerTRN: invoice.buyerTaxIdentifier,
    issueDate: invoice.invoiceIssueDate,
    englishBuyerName: invoice.buyerName,
    grossTotalIncludingVat: invoice.invoiceTotalAmountWithTax,
    vatValue: invoice.invoiceTotalTaxAmount,
    totalExcludingVat: invoice.invoiceTotalAmountWithoutTax,
  }));
};

export const parseBuyerInvoice = (buyerInvoice, t) => {
  return buyerInvoice.map((invoice) => ({
    invoiceType: t(`invoiceTypes.${invoice.invoiceTypeCode}`, {
      defaultValue: invoice.invoiceTypeCode,
    }),
    invoiceNumber: invoice.invoiceNumber,
    issueDate: invoice.invoiceIssueDate,
    englishSupplierName: invoice.sellerName,
    grossTotalIncludingVat: invoice.invoiceTotalAmountWithTax,
    vatValue: invoice.invoiceTotalTaxAmount,
    totalExcludingVat: invoice.invoiceTotalAmountWithoutTax,
    supplierTRN: invoice.sellerTaxIdentifier,
  }));
};
