import React, { useState, useEffect } from 'react';
import ViewInvoice from './ViewInvoice';
import { useParams } from 'react-router-dom';
import { useKeycloak } from '../../../../hooks/useKeycloak';
import {
  downloadXml,
  downloadPDFInvoice,
} from '../../../../helpers/invoiceHelpers';
import * as api from '../../../../services/apis';

const ViewInvoiceContainer = () => {
  const { type, id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);

  const { realm, userInfo } = useKeycloak();

  useEffect(() => {
    if (userInfo) {
      api.getInvoiceById(id, userInfo.tax_number).then((resp) => {
        setInvoice(resp.data);
        setLoading(false);
      });
    }
  }, [userInfo]);

  const handleDownloadXmlClick = () => {
    downloadXml(invoice.xml, invoice.invoiceNumber);
  };

  const handleDownloadPDFClick = () => {
    if (realm) {
      setDownloading(true);
      const currency = realm === 'eiv' ? 'OMR' : '';
      downloadPDFInvoice({
        data: invoice,
        currency,
        uuid: id,
        setLoading: setDownloading,
      });
    }
  };

  return (
    <ViewInvoice
      type={type}
      loading={loading}
      onDownloadXmlClick={handleDownloadXmlClick}
      onDownloadPdfClick={handleDownloadPDFClick}
      invoice={invoice}
      downloading={downloading}
    />
  );
};

export default React.memo(ViewInvoiceContainer);
