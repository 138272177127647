import React, { createContext, useState, useEffect, useCallback } from 'react';
import keycloak from './keycloak';
import * as constants from '../constants/common';

export const AuthContext = createContext();

const KeycloakProvider = ({
  children,
  KEYCLOAK_URL,
  KEYCLOAK_REALM,
  KEYCLOAK_CLIENT_ID,
}) => {
  const [userInfo, setUserInfo] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [realm, setRealm] = useState(null);

  const keycloakInstance = keycloak({
    KEYCLOAK_URL,
    KEYCLOAK_REALM,
    KEYCLOAK_CLIENT_ID,
  });

  useEffect(() => {
    keycloakInstance
      .init({ onLoad: 'login-required' })
      .then((authenticated) => {
        if (authenticated) {
          setIsAuthenticated(true);
          loadUserInfo();
          sessionStorage.setItem(constants.TOKEN_KEY, keycloakInstance.token);
          setToken(keycloakInstance.token);
          console.log({ keycloakInstance });
        }
      })
      .catch(console.error);
  }, []);

  const loadUserInfo = useCallback(async () => {
    try {
      const profile = await keycloakInstance.loadUserProfile();
      const tax_number = profile.attributes?.tax_number?.[0];
      setUserInfo({ ...profile, tax_number });
      setRealm(keycloakInstance.realm);
    } catch (error) {
      console.error('Failed to load user profile', error);
    }
  }, []);

  const login = useCallback(() => {
    keycloakInstance?.login();
  }, []);

  const logout = useCallback(() => {
    keycloakInstance.logout();
    setIsAuthenticated(false);
    setUserInfo(null);
    setToken(null);
    setRealm(null);
  }, []);

  const value = {
    isAuthenticated,
    token,
    logout,
    userInfo,
    login,
    realm,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default KeycloakProvider;
