import React, { useState } from 'react';
import DownloadButton from './DownloadButton';
import { useKeycloak } from '../../../../../../hooks/useKeycloak';
import { OvalSpinner } from '../../../../../../components/shared/OvalSpinner';

const DownloadButtonContainer = ({ rowData, ...restProps }) => {
  const [loading, setLoading] = useState(false);
  const { userInfo, realm } = useKeycloak();
  const handleDownload = () => {
    // if (userInfo) {
    //   const uuid = rowData.uniqueReferenceIdentifier;
    //   setLoading(true);
    //   api
    //     .getInvoiceById(uuid, userInfo.tax_number)
    //     .then((resp) => {
    //       const currency = realm === 'eiv' ? 'AED' : '';
    //       downloadPDFInvoice({ data: resp.data, currency, uuid, setLoading });
    //     })
    //     .catch(() => {
    //       setLoading(false);
    //     });
    // }
  };

  return loading ? (
    <OvalSpinner width={40} height={40} color="#26890d" />
  ) : (
    <DownloadButton {...restProps} onDownload={handleDownload} />
  );
};

export default React.memo(DownloadButtonContainer);
