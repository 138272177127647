import React, { useState, useEffect } from 'react';
import InvoiceView from './InvoiceView';
import {
  VIEW_OPTIONS,
  parseBuyerInvoice,
  parseSellerInvoices,
} from './InvoiceView.config';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '../../hooks/useKeycloak';
import * as api from '../../services/apis';

const InvoiceViewContainer = () => {
  const [view, setView] = useState('seller');
  const [originalInvoices, setOriginalInvoices] = useState([]);
  const [renderInvoices, setRenderInvoices] = useState(
    Array.from({ length: 4 }),
  );
  const [loading, setLoading] = useState(true);

  const { userInfo } = useKeycloak();

  const { t } = useTranslation();

  const addIds = (invoices) => {
    return invoices.map((invoice, i) => ({ ...invoice, number: i + 1 }));
  };

  const getSellerData = () => {
    if (userInfo) {
      api.getInvoiceViewBySellerTaxId(userInfo.tax_number).then((resp) => {
        const parsedData = parseSellerInvoices(resp.data, t);
        setRenderInvoices(parsedData);
        setOriginalInvoices(parsedData);
      });
    }
  };

  const getBuyerData = () => {
    if (userInfo) {
      api.getInvoiceViewByBuyerTaxId(userInfo.tax_number).then((resp) => {
        const parsedData = parseBuyerInvoice(resp.data, t);
        setRenderInvoices(parsedData);
        setOriginalInvoices(parsedData);
      });
    }
  };

  const getInvoicesList = (viewType) => {
    if (viewType === 'seller') {
      getSellerData();
    } else {
      getBuyerData();
    }
    setLoading(false);
  };

  useEffect(() => {
    getInvoicesList(view);
  }, [view, userInfo]);

  const invoicesWithIds = addIds(renderInvoices);

  return (
    <InvoiceView
      invoices={invoicesWithIds}
      setRenderInvoices={setRenderInvoices}
      originalInvoices={originalInvoices}
      loading={loading}
      setView={setView}
      view={view}
      viewOptions={VIEW_OPTIONS.map((option) => ({
        ...option,
        label: t(`invoice_list.${option.label}`),
      }))}
    />
  );
};

export default React.memo(InvoiceViewContainer);
