import React from 'react';
import styles from './ServiceProvider.styles.scss';
import { MainLayout } from '../../components/MainLayout';
import { PagesHeader } from '../../components/shared/PagesHeader';
import { useTranslation } from 'react-i18next';
import {
  ROUTES,
  FORM_CONFIG,
  DILIGENCE,
  ATTACHMENTS,
  DIRECTIONS,
  REQUIREMENTS,
} from './ServiceProvider.config';
import { Formik, Form } from 'formik';
import { FormikField } from '../../components/FormikField';
import cn from 'classnames';
import { INITIAL_VALUES } from './formConfig/initialValues';
import { FileUpload } from 'primereact/fileupload';
import { Checkbox } from 'primereact/checkbox';
import { Button } from '../../components/shared/Button';

const ServiceProvider = ({
  getInputType,
  onDiligenceChange,
  selectedDiligence,
  handleCancelClick,
  handleFormSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <MainLayout>
      <PagesHeader
        withBreadcrumb={true}
        title={t('service_provider')}
        routes={ROUTES}
      />
      <div className={styles.container}>
        <div className={cn(styles['initial-text-container'])}>
          <div className="mb-5">
            <p className="mb-3">
              {t(`service_provider_form.${DIRECTIONS.welcomeText}`)}
            </p>
            <ul>
              {DIRECTIONS.steps.map((step, i) => (
                <li className="mb-2" key={step.key}>
                  <strong>{`${i + 1}- `}</strong>
                  {t(`service_provider_form.${step.text}`)}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <p className="mb-3">
              {t(`service_provider_form.${REQUIREMENTS.note}`)}
            </p>
            <ul>
              {REQUIREMENTS.requirements.map((requirement) => (
                <li className="mb-2" key={requirement.key}>
                  <strong>{`- ${t(
                    `service_provider_form.${requirement.title}`,
                  )}: `}</strong>
                  {t(`service_provider_form.${requirement.desc}`)}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className={cn(styles.container)}>
        <Formik
          // validateOnMount
          enableReinitialize
          // validationSchema={validationSchema({ type, t })}
          onSubmit={(e) => handleFormSubmit(e)}
          initialValues={INITIAL_VALUES}
        >
          {({
            values,
            resetForm,
            setFieldValue,
            validateForm,
            setErrors,
            setTouched,
            setFieldTouched,
            submitCount,
          }) => (
            <>
              {console.log({ submitCount })}
              <Form>
                <div className={cn(styles['form-container'])}>
                  {FORM_CONFIG()?.map((section) => (
                    <div className="container">
                      <p className={cn(styles['section-title'])}>
                        {t(`service_provider_form.${section.title}`)}
                      </p>
                      <div className="row">
                        {section?.fields?.map((field) =>
                          field.type === 'multi' ? (
                            <div className="container">
                              <p className={cn(styles['secondary-title'])}>
                                {t(`service_provider_form.${field.label}`)}
                              </p>
                              <div className="row">
                                {field.fields.map((innerField) => (
                                  <FormikField
                                    name={innerField.name}
                                    component={getInputType(innerField.type)}
                                    componentProps={{
                                      className: cn(
                                        innerField.type !== 'textarea'
                                          ? styles.field
                                          : '',
                                        innerField.className,
                                      ),
                                      disabled: innerField.disabled,
                                      // ...getComponentProps(input),
                                      // disabledDates: input.disabledDates,
                                    }}
                                    controlProps={{
                                      labelProps: { className: styles.label },
                                      className: innerField.className,
                                    }}
                                    label={t(
                                      `service_provider_form.${innerField.label}`,
                                    )}
                                    required={innerField.required}
                                    key={innerField.name}
                                  />
                                ))}
                              </div>
                            </div>
                          ) : field.type === 'radio' ? (
                            <>
                              <FormikField
                                name={field.name}
                                component={getInputType(field.type)}
                                componentProps={{
                                  className: cn(
                                    field.type !== 'textarea'
                                      ? styles.field
                                      : '',
                                    field.className,
                                  ),
                                  ...field,
                                  setFieldValue,
                                  // ...getComponentProps(input),
                                  // disabledDates: input.disabledDates,
                                }}
                                controlProps={{
                                  labelProps: { className: styles.label },
                                  className: field.className,
                                }}
                                label={t(
                                  `service_provider_form.${field.label}`,
                                )}
                                required={field.required}
                                key={field.name}
                              />
                              {field.withAttachment &&
                                field.attachment.showWhenValueIs ===
                                  values[field.name] && (
                                  <div className="mb-5">
                                    <p className={cn(styles.label, 'mb-3')}>
                                      {t(
                                        `service_provider_form.${field.attachment.label}`,
                                      )}
                                    </p>
                                    <FileUpload
                                      mode="basic"
                                      url="/api/upload"
                                      accept="image/*"
                                      name={field.attachment.name}
                                      chooseLabel={t('common.choose')}
                                      //   customUpload
                                      //   uploadHandler={customBase64Uploader}
                                    />
                                  </div>
                                )}
                              {field.withDescription &&
                                field.descriptionInput.showWhenValueIs ===
                                  values[field.name] && (
                                  <FormikField
                                    name={field.descriptionInput.name}
                                    component={getInputType(
                                      field.descriptionInput.type,
                                    )}
                                    componentProps={{
                                      className: cn(
                                        field.descriptionInput.type !==
                                          'textarea'
                                          ? styles.field
                                          : '',
                                        field.descriptionInput.className,
                                      ),
                                      disabled: field.disabled,
                                      // ...getComponentProps(input),
                                      // disabledDates: input.disabledDates,
                                    }}
                                    controlProps={{
                                      labelProps: { className: styles.label },
                                      className:
                                        field.descriptionInput.className,
                                    }}
                                    label={t(
                                      `service_provider_form.${field.descriptionInput.label}`,
                                    )}
                                    required={field.descriptionInput.required}
                                    key={field.descriptionInput.name}
                                  />
                                )}
                            </>
                          ) : (
                            <FormikField
                              name={field.name}
                              component={getInputType(field.type)}
                              componentProps={{
                                className: cn(
                                  field.type !== 'textarea' ? styles.field : '',
                                  field.className,
                                ),
                                disabled: field.disabled,
                                // ...getComponentProps(input),
                                // disabledDates: input.disabledDates,
                              }}
                              controlProps={{
                                labelProps: { className: styles.label },
                                className: field.className,
                              }}
                              label={t(`service_provider_form.${field.label}`)}
                              required={field.required}
                              key={field.name}
                            />
                          ),
                        )}
                      </div>
                    </div>
                  ))}

                  <div className="container mt-5">
                    <p className={cn(styles['section-title'])}>
                      {t('service_provider_form.document_upload')}
                    </p>
                    <p className={cn(styles['diligence-desc'])}>
                      {t('service_provider_form.document_upload_desc')}
                    </p>
                    <div className="row gap-3">
                      {ATTACHMENTS.map((attachment) => (
                        <div className="mt-3 col-3 d-flex flex-column justify-content-between">
                          <label className={cn(styles.label, 'mb-3')}>
                            {t(`service_provider_form.${attachment.label}`)}
                          </label>
                          <FileUpload
                            name={attachment.name}
                            mode="basic"
                            url="/api/upload"
                            accept="image/*"
                            chooseLabel={t('common.choose')}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className={cn(styles['due-diligence'], 'container')}
                    id="diligence"
                  >
                    <p className={cn(styles['section-title'])}>
                      {t('service_provider_form.confirmation')}
                    </p>
                    <div className="flex flex-column gap-3">
                      {DILIGENCE.map((diligence) => {
                        return (
                          <div
                            key={diligence.key}
                            className="flex align-items-center mb-3"
                          >
                            <Checkbox
                              inputId={diligence.key}
                              name="diligence"
                              value={diligence}
                              onChange={onDiligenceChange}
                              checked={selectedDiligence.some(
                                (item) => item.key === diligence.key,
                              )}
                              className={cn(
                                submitCount > 0 &&
                                  !selectedDiligence.some(
                                    (item) => item.key === diligence.key,
                                  )
                                  ? 'p-invalid'
                                  : '',
                              )}
                            />
                            <label htmlFor={diligence.key} className="mx-2">
                              {t(`service_provider_form.${diligence.label}`)}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className={styles.buttonsContainer}>
                    <Button
                      className={'col-2 mx-2'}
                      variant={'outlined'}
                      type="button"
                      onClick={handleCancelClick}
                    >
                      {t('cancel')}
                    </Button>
                    <Button className={'col-2 mx-2'} type="submit">
                      {t('common.submit')}
                    </Button>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
      <div />
    </MainLayout>
  );
};

export default React.memo(ServiceProvider);
